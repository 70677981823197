const themeConfig = {
  colors: {
    primary: '#0099E1',
    yellow: '#FF9F00',
    red: '#D9534F',
    green: '#5CB85C',
    background: '#FFFFFF',
    defaultFontColor: '#FFFFFF',
  },
  layout: {
    pagePadding: 80,
  },
  fonts: {
    headline: 'DIN Condensed',
    copy: 'Open Sans',
  }
};

export default themeConfig