import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Franz is an award-winning messaging & productivity app. Combining popular messaging services like WhatsApp, Facebook Messenger, Slack, and Telegram into a single elegant and easy-to-use desktop app, Franz helps you stay connected.`}</p>
    <p>{`Hundreds of thousands of people rely upon Franz every day to keep in touch with friends and family, and to get their work done.`}</p>
    <p>{`Franz ToDos can be used through the web app from all devices, or the Franz desktop app on Mac, Windows and Linux.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      