import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Icon = makeShortcode("Icon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Features`}</h1>
    <ul>
      <li parentName="ul"><h3 parentName="li"><Icon icon="iconShare" iconByTitle mdxType="Icon" />{` Shared Lists`}</h3><ul parentName="li">
          <li parentName="ul">{`Create lists and share them with your family or colleagues to keep shared tasks in sight`}</li>
        </ul></li>
      <li parentName="ul"><h3 parentName="li"><Icon icon="iconSearch" iconByTitle mdxType="Icon" />{` Powerful search`}</h3><ul parentName="li">
          <li parentName="ul">{`Find what you’re looking for by searching for to-dos, for tags with #tag or in lists with +list`}</li>
        </ul></li>
      <li parentName="ul"><h3 parentName="li"><Icon icon="iconFranz" iconByTitle mdxType="Icon" />{` Integrated in Franz Messenger`}</h3><ul parentName="li">
          <li parentName="ul">{`Use ToDos in Franz Messenger like any other service, or try the native sidebar`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      