import React from 'react';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';
import { Helmet } from 'react-helmet';

import { useStaticQuery, graphql } from 'gatsby';
import { breakpoints } from '../utils/breakpoints';
import Layout from '../components/layout';
import Index from '../content/index.mdx';
import Intro from '../content/intro.mdx';
import Share from '../content/share.mdx';
import Messages from '../content/messages.mdx';
import Features from '../content/features.mdx';
import Franz from '../content/franz.mdx';
import Header from '../components/header';
import FranzFeatures from '../content/franzFeatures.mdx';
import Container from '../components/container';
import themeConfig from '../utils/theme';
import {
  iconWindows, iconApple, iconUbuntu, iconDownload, iconRocket,
} from '../utils/Icons';
import Icon from '../components/Icon';

const shortcodes = { Header };

const IntroContainer = styled.section`
  background: ${(props) => props.theme.colors.primary};
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    border-style: solid;
    border-width: 100px 0px 0 100vw;
    border-color: ${(props) => props.theme.colors.primary} transparent transparent transparent;
    width: 100%;
    height: 100px;
    background: ${(props) => props.theme.colors.yellow};
  }
`;

const ShareContainer = styled.section`
  background: ${(props) => props.theme.colors.yellow};
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    border-style: solid;
    border-width: 180px 100vw 0 0;
    border-color: transparent ${(props) => props.theme.colors.red} transparent transparent;
    width: 100%;
    height: 100px;
  }
`;

const MessagesContainer = styled.section`
  background: ${(props) => props.theme.colors.red};
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    border-style: solid;
    border-width: 100px 0px 0 100vw;
    border-color: ${(props) => props.theme.colors.red} transparent transparent transparent;
    background: ${(props) => props.theme.colors.green};
    width: 100%;
    width: 100%;
    height: 100px;
  }
`;

const FeaturesContainer = styled.section`
  background: ${(props) => props.theme.colors.green};
  position: relative;
  overflow: hidden;
  text-align: center;
  
  h1 {
    text-align: center;
    margin-bottom: 100px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    border-style: solid;
    border-width: 100px 100vw 0px 0;
    border-color: transparent ${(props) => props.theme.colors.primary} transparent transparent;
    width: 100%;
    height: 100px;
    background: ${(props) => props.theme.colors.green};
  }
`;

const GridContainer = styled.div`
  ul {
    list-style-type: none;
    margin: 0;
  }

  & > ul {
    text-align: center;
    
    ${breakpoints[1]} {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
      grid-template-rows: auto;
    }

    li {
      margin-bottom: 80px;

      ${breakpoints[1]} {
        margin-bottom: 20px;
      }
    }
  }

  & svg {
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 100px;
  }

  & > ul > li > ul {
    font-family: "${themeConfig.fonts.copy}";
    font-size: 1rem;
  }
`;

const FranzContainer = styled.section`
  background: ${(props) => props.theme.colors.primary};
  position: relative;
  overflow: hidden;

  & svg {
    margin: 0 2px 0 8px;
  }

  & a {
    text-decoration: none;
    
    &:hover {
      opacity: 0.8;
    }
  }

  & a:hover {
    opacity: 0.8;
    color: #FFF;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    border-style: solid;
    border-width: 100px 100vw 0px 0;
    border-color: transparent ${(props) => props.theme.colors.red} transparent transparent;
    width: 100%;
    height: 100px;
    background: ${(props) => props.theme.colors.primary};
  }
`;

const FranzContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  ${breakpoints[1]} {
    flex-direction: row-reverse;
  }
`;

const FranzContent = styled.div`
`;

const FranzImage = styled.img`
  width: 80%;
  align-self: center;
  
  ${breakpoints[1]} {
    width: 50%;
    flex-direction: row-reverse;
    margin-left: 100px;
  }
`;

const DownloadButton = styled.a`
  display: block;
  background: #FFF;
  color: ${themeConfig.colors.primary};
  padding: 10px 30px;
  border: 0;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 80px auto 20px auto;
  border-radius: 80px;
  letter-spacing: -0.025rem;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
    color: ${themeConfig.colors.primary} !important;
  }

  & svg {
    margin-left: 0px;
    margin-right: 20px;
    top: -3px;
    position: relative;
  }
`;

const LaunchAppContainer = styled.section`
  background: ${themeConfig.colors.red};
  display: flex;
  padding-bottom: 70px;
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    border-style: solid;
    border-width: 50px 0px 0 100vw;
    border-color: ${(props) => props.theme.colors.red} transparent transparent transparent;
    background: #FFF;
    width: 100%;
    width: 100%;
    height: 50px;
  }
`;

const LaunchAppButton = styled.a`
  display: block;
  background: #FFF;
  color: ${themeConfig.colors.red};
  padding: 10px 30px;
  border: 0;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 80px auto 20px auto;
  border-radius: 80px;
  letter-spacing: -0.025rem;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
    color: ${themeConfig.colors.red} !important;
  }

  & svg {
    margin-left: 0px;
    margin-right: 20px;
    top: 0px;
    position: relative;
  }
`;

const Footer = styled.section`
  padding-top: 100px;
  font-size: 0.8rem;
  text-align: center;
  color: #000;

  & a {
    display: inline-block;
    margin: 0 10px;
    text-decoration: none;
  }
`;

export default function Home() {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl,
            franzUrl,
            appUrl
          }
        }
      }
    `,
  );

  return (
    <MDXProvider shortcodes={shortcodes}>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href={data.site.siteMetadata.siteUrl} />
        <meta property="og:title" content="Franz ToDos – Stay On Top Of Your Tasks" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
        <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}/fbshareimage.png`} />
        <meta property="og:description" content="Franz ToDos help you structure your workflow and keep work and private life organized. Available as part of the Franz Messenger desktop app, and on all devices through a beautifully crafted web app." />
      </Helmet>
      <Layout>
        <Index />
        <Header />
        <IntroContainer id="intro">
          <Container
            image="organized.svg"
            imagePosition="right"
          >
            <Intro />
          </Container>
        </IntroContainer>
        <ShareContainer>
          <Container
            image="share.svg"
            imagePosition="left"
          >
            <Share />
          </Container>
        </ShareContainer>
        <MessagesContainer>
          <Container
            image="messages.svg"
            imagePosition="right"
          >
            <Messages />
          </Container>
        </MessagesContainer>
        <FeaturesContainer>
          <Container>
            <GridContainer>
              <Features />
            </GridContainer>
          </Container>
        </FeaturesContainer>
        <FranzContainer>
          <Container>
            <h1 css={{
              textAlign: 'center',
            }}
            >
              Franz Multi Messaging App

            </h1>
            <FranzContentContainer>
              <FranzImage src="./franz-screenshot.png" alt="" />
              <FranzContent>
                <Franz />
              </FranzContent>
            </FranzContentContainer>
            <DownloadButton
              href={`${data.site.siteMetadata.franzUrl}/#download`}
            >
              <Icon icon={iconDownload} />
              Download Franz App
            </DownloadButton>
            <p
              css={{
                textAlign: 'center',
                fontSize: '0.8rem',
              }}
            >
              Available for:
              {' '}
              <a href={`${data.site.siteMetadata.franzUrl}/download?platform=win`}>
                <Icon icon={iconWindows} />
                {' '}
                Windows
                {' '}
              </a>
              <a href={`${data.site.siteMetadata.franzUrl}/download?platform=mac`}>
                <Icon icon={iconApple} />
                {' '}
                macOS
                {' '}
              </a>
              <a href={`${data.site.siteMetadata.franzUrl}/#download`}>
                <Icon icon={iconUbuntu} />
                {' '}
                Linux
              </a>
            </p>

            <GridContainer css={{
              textAlign: 'center',

              '& h1': {
                textAlign: 'center',
                marginTop: 100,
              },
            }}
            >
              <FranzFeatures />
            </GridContainer>
          </Container>
        </FranzContainer>
        <LaunchAppContainer>
          <LaunchAppButton href={`${data.site.siteMetadata.appUrl}?ref=launch-app-button`}>
            <Icon icon={iconRocket} />
            {' '}
            Launch Franz ToDos
          </LaunchAppButton>
        </LaunchAppContainer>
        <Footer>
          <p>
            <a href={data.site.siteMetadata.franzUrl}>meetfranz.com</a>
            <a href={`${data.site.siteMetadata.franzUrl}/privacy`}>Privacy Statement</a>
            <a href={`${data.site.siteMetadata.franzUrl}/terms`}>Terms of Service</a>
          </p>
          <p>
            <a href={data.site.siteMetadata.franzUrl}>
              <img
                src="./adlkfranz.png"
                css={{
                  width: 150,
                }}
                alt="Adlk \ Franz"
              />
            </a>
          </p>
        </Footer>
      </Layout>
    </MDXProvider>
  );
}
