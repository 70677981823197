// import React from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { breakpoints } from '../utils/breakpoints';

const Container = ({
  children,
  image,
  imagePosition = 'left',
  imageAltText = '',
}) => {
  return (
    <div
      css={{
        padding: 30,

        [breakpoints[2]]: {
          padding: '100px 100px 150px',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          maxWidth: 1100,
          margin: '0 auto',
          flexDirection: 'column-reverse',

          [breakpoints[1]]: {
            flexDirection: imagePosition === 'left' ? 'row-reverse' : 'row',
          },
        }}
      >
        <div
          css={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',

            [breakpoints[1]]: {
              width: image ? '50%' : '100%',
            },
          }}
        >
          {children}
        </div>
        {
          image && (
            <img
              src={image}
              alt={imageAltText}
              css={{
                width: '80%',
                marginBottom: 80,
                alignSelf: 'center',

                [breakpoints[1]]: {
                  [imagePosition === 'left' ? 'marginRight' : 'marginLeft']: 100,
                  width: image ? '50%' : '100%',
                  marginBottom: 0,
                },
              }}
            />
          )
        }
      </div>
    </div >
  );
};

Container.propTypes = {
  imagePosition: PropTypes.oneOf(['left', 'right']),
}

export default Container;