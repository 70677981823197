import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Franz Features`}</h1>
    <ul>
      <li parentName="ul"><h3 parentName="li">{`Multi Messaging`}</h3><ul parentName="li">
          <li parentName="ul">{`All your favorite messaging and productivity services combined in one single app.`}</li>
        </ul></li>
      <li parentName="ul"><h3 parentName="li">{`Workspaces`}</h3><ul parentName="li">
          <li parentName="ul">{`Multiple sets of services for different work environments or projects.`}</li>
        </ul></li>
      <li parentName="ul"><h3 parentName="li">{`Team Management`}</h3><ul parentName="li">
          <li parentName="ul">{`One team manager, one invoice, as many licenses as you need.`}</li>
        </ul></li>
      <li parentName="ul"><h3 parentName="li">{`Cloud Sync`}</h3><ul parentName="li">
          <li parentName="ul">{`Set up your account in one place and use Franz on as many devices as you want.`}</li>
        </ul></li>
      <li parentName="ul"><h3 parentName="li">{`Multiple Instances`}</h3><ul parentName="li">
          <li parentName="ul">{`Run as many instances of a service as you want, for example, 4 different WhatsApp accounts.`}</li>
        </ul></li>
      <li parentName="ul"><h3 parentName="li">{`Extensibility`}</h3><ul parentName="li">
          <li parentName="ul">{`Add custom websites, like your company intranet or your favorite news site.`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      