import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Give Structure `}<br />{`To Your Day`}</h1>
    <p>{`Use lists and tags to structure your workflow, assign priorities to your ToDos and keep work and private life organized.`}</p>
    <p>{`Franz ToDos are available as part of the Franz Messenger desktop app, and on all devices through our beautiful web app.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      