import React from 'react';
import styled from '@emotion/styled';

import { useStaticQuery, graphql } from 'gatsby';
import Content from '../content/header.mdx';

import { breakpoints } from '../utils/breakpoints';
import themeConfig from '../utils/theme';
import { iconRocket } from '../utils/Icons';
import Icon from './Icon';

const Header = styled.header`
  background-image: ${(props) => `linear-gradient( to left top, ${props.theme.colors.primary} 50%, ${props.theme.colors.red}ed 50%)`};
  padding: 30px;
  display: flex;
  flex-direction: column;
  
  ${breakpoints[2]} {
    min-height: 80vh;
    padding: 30px 100px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;

  h1 {
    font-size: 25vw;
    line-height: 23vw;
    color: #FFF;
    margin-top: 6vw;

    ${breakpoints[1]} {
      font-size: 14vw;
      line-height: 12vw;
    }

    ${breakpoints[2]} {
      font-size: 14vw;
      line-height: 12vw;
    }
  }
  
  a {
    text-decoration: none;
    
    &:hover {
      color: #FFF;
      opacity: 0.8;
    }
  }
`;

const HeaderNavigation = styled.div`
  display: flex;
  flex-direction: column-reverse;
  
  ${breakpoints[2]} {
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-direction: row;
  }
`;

const LogoContainer = styled.div`
  font-family: '${themeConfig.fonts.headline}';
  text-transform: uppercase;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  
  ${breakpoints[2]} {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

const Logo = styled.img`
  width: 100px;
  margin: 0 20px 0;

  ${breakpoints[2]} {
    margin-bottom: 0;
  }
`;

const Navigation = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  
  ${breakpoints[2]} {
    margin-bottom: 0;
    margin-right: inherit;
  }

  a {
    color: ${themeConfig.colors.red};
    background: #FFF;
    border-radius: 40px;
    padding: 6px 15px;
    font-weight: 700;
    text-decoration: none;
    position: relative;

    &:hover {
      opacity: 0.8;
    }

    svg {
      margin-top: 2px;
      margin-right: 10px;
    }
  }
`;

const HeaderComponent = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            appUrl
          }
        }
      }
    `,
  );

  return (
    <Header>
      <HeaderNavigation>
        <LogoContainer>
          <Logo
            src="/logo.svg"
            alt="Franz ToDos Logo"
          />
          Franz Todos
        </LogoContainer>
        <Navigation>
          <a href={`${data.site.siteMetadata.appUrl}?ref=header`}>
            <Icon icon={iconRocket} />
            {' '}
            Launch App
          </a>
        </Navigation>
      </HeaderNavigation>
      <ContentContainer>
        <Content />
      </ContentContainer>
    </Header>
  );
};

export default HeaderComponent;
