import {
  FontAwesomeIcon,
} from '@fortawesome/react-fontawesome';
import React from 'react';

import * as Icons from '../utils/Icons';

const Icon = ({ icon, size = 'lg', className, iconByTitle = false, ...props }) => {
  return (
    <FontAwesomeIcon icon={iconByTitle ? Icons[icon] : icon} size={size} className={className} {...props} />
  );
};

export default Icon;
