import React from 'react';
import styled from "@emotion/styled";
import { ThemeProvider } from 'emotion-theming'
import { MDXProvider } from '@mdx-js/react';
import { Global, css } from '@emotion/core'

import theme from '../utils/theme';
import Header from './header';
import Icon from './Icon';
import { breakpoints } from '../utils/breakpoints';

const shortcodes = {
  Header,
  Icon
}

const Container = styled.div`
  background: ${props => props.theme.colors.background};
`;

const Layout = ({ children }) => {
  return (
    <MDXProvider components={shortcodes}>
      <ThemeProvider theme={theme}>
        <Global
          styles={css`
            html, body {
              background: ${theme.colors.background};
            }
            html {
              padding: 10px;
              
              ${breakpoints[1]} {
                padding: 60px;
              }
            }
          `}
        />
        <Container>
          {children}
        </Container>
      </ThemeProvider>
    </MDXProvider>
  )
}

export default Layout;